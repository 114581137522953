<template>
    <div id="page-lap-pelayanan-harian">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_awal"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_awal"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_akhir"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_akhir"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Pasien
                    </template>
                    <multiselect
                      :options="listPasien"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_lengkap"
                      size="sm"
                      v-model="data_search.rm_id"
                      :disabled="disabled_field"
                      @search-change="getPasien"
                      :loading="tableBusy"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli
                    </template>
                    <multiselect
                      :options="listPoli"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_poli"
                      size="sm"
                      v-model="data_search.poli"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Diagnosa
                    </template>
                    <multiselect
                      :options="listDiagnosa"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nm_diag"
                      size="sm"
                      v-model="data_search.ms_diagnosa_id"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tenaga Medis
                    </template>
                    <multiselect
                      :options="listTenagaMedis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.ms_tenaga_medis_id"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Rujukan FKTP
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.rujukan_fktp"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Rujuk RS
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.rujuk_rs"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <!-- <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Wilayah
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.wilayah"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group> -->
                  <!-- <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Jenis Kunjungan
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.jenis_kunjungan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group> -->
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Jenis Kelamin
                    </template>
                    <multiselect
                      :options="$store.state.data_static.jenis_kelamin"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.jenis_kelamin"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Capaian SPM
                    </template>
                    <multiselect
                      :options="listCapaianSPM"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.capaian_spm"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kecamatan
                    </template>
                    <multiselect
                      :options="listKecamatan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_kecamatan"
                      size="sm"
                      v-model="data_search.kecamatan"
                      @search-change="getKecamatan"
                      @select="getKelurahan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kelurahan/Desa
                    </template>
                    <multiselect
                      :options="listKelurahan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_kelurahan"
                      size="sm"
                      v-model="data_search.kelurahan"
                      @search-change="getKelurahan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Merokok
                    </template>
                    <multiselect
                      :options="listMerokok"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.merokok"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kurang Aktifitas Fisik
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.kurang_aktifitas_fisik"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Gula Berlebihan
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.gula_belebihan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Garam Berlebihan
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.garam_berlebihan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Lemak Berlebihan
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.lemak_berlebihan"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kurang Sayur dan Buah
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.kurang_sayur_dan_buah"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Konsumsi Alkohol
                    </template>
                    <multiselect
                      :options="$store.state.data_static.option"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.konsumsi_alkohon"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - KUNJUNGAN PTM</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ totalRows }}</strong></h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table-simple responsive class="table-lap-pelayanan-harian">
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th rowspan="4" style="width: 10px; position:sticky; left:0; min-width:50px; background-color: #fff;">No</b-th>
                        <b-th rowspan="4" style="position:sticky; left:50px; min-width:150px; background-color: #fff;">Tanggal Pemeriksaan</b-th>
                        <b-th rowspan="4" style="position:sticky; left:200px; min-width:160px; background-color:#fff">Nama Pasien</b-th>
                        <b-th colspan="10" style="min-width: 130px;">Identitas Pasien</b-th>
                        <b-th colspan="8" style="min-width: 200px;">Riwayat Penyakit Tidak Menular pada Keluarga</b-th>
                        <b-th colspan="7" style="min-width: 100px;">Riwayat Penyakit Tidak Menular pada Diri Sendiri	</b-th>
                        <b-th colspan="7" style="min-width: 100px;">Faktor Risiko</b-th>
                        <b-th colspan="2" style="min-width: 100px;">Tekanan Darah</b-th>
                        <b-th colspan="2" style="min-width: 100px;">IMT</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Lingkar Perut(cm)</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Pemeriksaan Gula</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Rujuk RS</b-th>
                        <b-th rowspan="4" style="min-width: 300px;">Diagnosa</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Terapi Farmakologi</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Konseling, Informasi, dan Edukasi Kesehatan</b-th>
                        <b-th colspan="6" style="min-width: 100px;">Gangguan Penglihatan</b-th>
                        <b-th colspan="9" style="min-width: 100px;">Gangguan Pendengaran</b-th>
                        <b-th colspan="4" style="min-width: 100px;">Pemeriksaan IVA & Sanadis</b-th>
                        <!-- <b-th colspan="4" style="min-width: 100px;">Form UBM</b-th>
                        <b-th colspan="4" style="min-width: 100px;">Sesuai Standar</b-th> -->
                        <b-th colspan="27" style="min-width: 100px;">Skrining Komplikasi Organ</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Rontgen</b-th>
                        <b-th rowspan="1" style="min-width: 100px;">Skrining</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Usia</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Kriteria Usia</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Skrining Organ</b-th>
                        <b-th rowspan="4" style="min-width: 100px;">Skor Puma</b-th>
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th rowspan="3" style="min-width: 100px;">NIK</b-th>
                        <b-th rowspan="3" style="min-width: 150px;">Tanggal Lahir</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Jenis Kelamin</b-th>
                        <b-th rowspan="3" style="min-width: 150px;">Provinsi</b-th>
                        <b-th rowspan="3" style="min-width: 150px;">Kota/Kab</b-th>
                        <b-th rowspan="3" style="min-width: 200px;">Alamat</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">No. Telp/HP</b-th>
                        <b-th rowspan="3" style="min-width: 150px;">Pekerjaan</b-th>
                        <b-th rowspan="3" style="min-width: 150px;">Status Perkawinan</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Golongan Darah</b-th>

                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Diabetes</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Hipertensi</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Jantung</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Stroke</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Asma</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Kanker</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Kolesterol Tinggi</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Benjolan Payudara</b-th>

                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Diabetes</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Hipertensi</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Jantung</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Stroke</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Asma</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Penyakit Kanker</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Kolesterol Tinggi</b-th>

                        <b-th rowspan="3" style="min-width: 100px;">Merokok</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Kurang Aktivitas Fisik</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Gula Berlebihan</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Garam Berlebihan</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Lemak Berlebihan</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Kurang Sayur dan Buah</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Konsumsi Alkohol</b-th>

                        <b-th rowspan="3" style="min-width: 100px;">Sistol</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Diastol</b-th>

                        <b-th rowspan="3" style="min-width: 100px;">Tinggi Badan(cm)</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Berat Badan(Kg)</b-th>

                        <b-th rowspan="1" colspan="3" style="min-width: 100px;">Katarak</b-th>
                        <b-th rowspan="1" colspan="3" style="min-width: 100px;">Kelainan Refraksi	</b-th>

                        <b-th rowspan="1" colspan="3" style="min-width: 100px;">	Curiga Tuli Kongentinal	</b-th>
                        <b-th rowspan="1" colspan="3" style="min-width: 100px;">OMSK/Congek	</b-th>
                        <b-th rowspan="1" colspan="3" style="min-width: 100px;">Serumen	</b-th>

                        <b-th rowspan="1" colspan="2" style="min-width: 100px;">Pemeriksaan IVA</b-th>
                        <b-th rowspan="1" colspan="2" style="min-width: 100px;">Pemeriksaan Sanadnis</b-th>

                        <!-- <b-th rowspan="3" style="min-width: 100px;">Konseling</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">CAR</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Rujuk UBM</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Kondisi</b-th>

                        <b-th rowspan="3" style="min-width: 100px;">Kunjungan</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Edukasi</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Pengobatan</b-th>
                        <b-th rowspan="3" style="min-width: 100px;">Rujukan</b-th> -->

                        <b-th colspan="7" style="min-width: 100px;">Mata</b-th>
                        <b-th colspan="3" style="min-width: 100px;">Kardiovaskular</b-th>
                        <b-th colspan="4" style="min-width: 100px;">Ginjal</b-th>
                        <b-th colspan="3" style="min-width: 100px;">Hati</b-th>
                        <b-th colspan="5" style="min-width: 100px;">Saraf dan Otot</b-th>
                        <b-th colspan="5" style="min-width: 100px;">Profil Lipid</b-th>
                        
                        <b-th rowspan="3" style="min-width: 100px;">Ginjal + 1 Organ</b-th>
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th rowspan="2" style="min-width: 100px;">Mata Kanan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Mata Kiri</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Rujuk RS</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Mata Kanan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Mata Kiri</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">Telinga Kanan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Telinga Kiri</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Rujuk RS</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Telinga Kanan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Telinga Kiri</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Rujuk RS</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Telinga Kanan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Telinga Kiri</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">Hasil IVA</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Tindak Lanjut IVA Positif</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Hasil Sanadis</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Tindak Lanjut Sanadis</b-th>

                        <b-th colspan="2" style="min-width: 100px;">Refraksi</b-th>
                        <b-th colspan="2" style="min-width: 100px;">Lensa</b-th>
                        <b-th colspan="2" style="min-width: 100px;">Retina</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Mata Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">Carta</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">EKG</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Kardiovaskular Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">Urinalisis - Proteinuria	</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Ureum</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Kreatinin</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Ginjal Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">SGOT</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">SGPT</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Hati Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">Ulkus Diabbetikum</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Neuropati Diabetes Melitus</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">NDM Motorik</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">NDM Sensorik</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Saraf & Otot Rujuk RS</b-th>

                        <b-th rowspan="2" style="min-width: 100px;">Kolesterol Total</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">LDL</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">HDL</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Trigliserida</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Poofil Lipid Rujuk RS</b-th>
                        <!-- <b-th rowspan="2" style="min-width: 100px;">Rujuk</b-th> -->
                      </b-tr>
                      <tr class="text-center">
                        <b-th rowspan="1" style="min-width: 100px;">Mata Kanan</b-th>
                        <b-th rowspan="1" style="min-width: 100px;">Mata Kiri</b-th>
                        <b-th rowspan="1" style="min-width: 100px;">Mata Kanan</b-th>
                        <b-th rowspan="1" style="min-width: 100px;">Mata Kiri</b-th>
                        <b-th rowspan="1" style="min-width: 100px;">Mata Kanan</b-th>
                        <b-th rowspan="1" style="min-width: 100px;">Mata Kiri</b-th>
                        
                      </tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td style="position:sticky;left:0;min-width:50px;background-color: #fff;">{{ item.no }}</b-td>
                        <b-td style="position:sticky;left:50px;min-width:150px;background-color: #fff;">{{ $moment(item.tanggal_ptm).format('LLLL') }}</b-td>
                        <b-td style="position:sticky;left:200px;min-width:160px;background-color: #fff;">{{ item.nama_lengkap }}</b-td>

                        <!-- pasien -->
                        <b-td>{{ item.nik }}</b-td>
                        <b-td>{{ $moment(item.tanggal_lahir).format('LL') }}</b-td>
                        <b-td>{{ item.jenis_kelamin == 'L' ? 'Laki - Laki' : 'Perempuan' }}</b-td>
                        <b-td>{{ item.nama_provinsi || '-' }}</b-td>
                        <b-td>{{ item.nama_kota || '-' }}</b-td>
                        <b-td>{{ item.alamat_sekarang || '-' }}</b-td>
                        <b-td>{{ item.no_telp || '-' }}</b-td>
                        <b-td>{{ item.pekerjaan || '-' }}</b-td>
                        <b-td>{{ item.status_perkawinan || '-' }}</b-td>
                        <b-td>{{ item.nama_golongan_darah || '-' }}</b-td>

                        <!-- Riwayat Penyakit Tidak Menular pada Keluarga -->
                        <b-td>{{ item.diabetes_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.hipertensi_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.jantung_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.struk_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.asma_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.kanker_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.kolesterol_k ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.benjolan_k ? 'ya' : 'tidak' }}</b-td>

                        <!-- Riwayat Penyakit Tidak Menular pada Diri Sendiri	 -->
                        <b-td>{{ item.diabetes_b ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.hipertensi_b ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.jantung_b ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.struk_b ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.asma_b ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.kanker_b ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.kolesterol_b ? 'ya' : 'tidak' }}</b-td>

                        <!-- Faktor Risiko -->
                        <b-td>{{ item.merokok || '-' }}</b-td>
                        <b-td>{{ item.kurang_aktivitas_fisik ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.gula_berlebihan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.garam_berlebihan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.lemak_berlebihan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.kurang_buah_sayur ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.konsumsi_alkohol ? 'ya' : 'tidak' }}</b-td>

                        <!-- Tekanan Darah -->
                        <b-td>{{ item.sistole_ptm || '-' }}</b-td>
                        <b-td>{{ item.diastole_ptm || '-' }}</b-td>

                        <!-- IMT -->
                        <b-td>{{ item.tinggi_badan_ptm || '-' }}</b-td>
                        <b-td>{{ item.berat_badan_ptm || '-' }}</b-td>

                        <b-td>{{ item.lingkar_perut || '-' }}</b-td>
                        <b-td>{{ item.pemeriksaan_gula || '-' }}</b-td>
                        <b-td>{{ item.rujuk_rs ? 'ya' : 'tidak' }}</b-td>
                        <b-td>
                          <ol class="m-0 px-3" v-if="item.diagnosa && item.diagnosa.length">
                            <li v-for="(d, keyD) of item.diagnosa" :key="keyD">{{d.kode_diagnosa}} - {{d.nama_diagnosa}}</li>
                          </ol>
                          <span v-else>-</span>
                        </b-td>
                        <b-td>{{ item.terapi_farmatologi ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.konseling || '-' }}</b-td>

                        <!-- Gangguan Penglihatan -->
                        <b-td>{{ item.katarak_mata_kanan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.katarak_mata_kiri ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.rujuk_rs_katarak ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.refraksi_mata_kanan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.refraksi_mata_kiri ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.rujuk_rs_refraksi ? 'ya' : 'tidak' }}</b-td>

                        <!-- Gangguan Pendengaran -->
                        <b-td>{{ item.ctk_kanan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.ctk_kiri ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.rujuk_rs_ctk ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.omsk_kanan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.omsk_kiri ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.rujuk_rs_omsk ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.serumen_kanan ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.serumen_kiri ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.rujuk_rs_serumen ? 'ya' : 'tidak' }}</b-td>

                        <!-- Pemeriksaan IVA & Sanadis -->
                        <b-td>{{ item.hasil_iva || '-' }}</b-td>
                        <b-td>{{ item.tindak_lanjut_iva || '-' }}</b-td>
                        <b-td>{{ item.hasil_sadanis || '-' }}</b-td>
                        <b-td>{{ item.tindak_lanjut_sadanis || '-' }}</b-td>
                      
                        <!-- Form UBM -->
                        <!-- <b-td>-</b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td> -->
                        
                        <!-- Sesuai Standar -->
                        <!-- <b-td>-</b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td> -->
                        
                        <!-- Skrining Komplikasi Organ -->
                        <b-td>{{ item.refraksi_kanan || '-' }}</b-td>
                        <b-td>{{ item.refraksi_mata_kiri || '-' }}</b-td>
                        <b-td>{{ item.lensa_kanan || '-' }}</b-td>
                        <b-td>{{ item.lensa_kiri || '-' }}</b-td>
                        <b-td>{{ item.retina_kanan || '-' }}</b-td>
                        <b-td>{{ item.retina_kiri || '-' }}</b-td>
                        <b-td>{{ item.rujuk_rs_co_mata || '-' }}</b-td>
                        <b-td>{{ item.carta || '-' }}</b-td>
                        <b-td>{{ item.is_normal_ekg ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.rujuk_rs_co_kardiovaskular ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.urinalis_proteinuria || '-' }}</b-td>
                        <b-td>{{ item.ureum || '-' }}</b-td>
                        <b-td>{{ item.kreatinin || '-' }}</b-td>
                        <b-td>{{ item.rujuk_rs_co_ginjal || '-' }}</b-td>
                        <b-td>{{ item.sgot || '-' }}</b-td>
                        <b-td>{{ item.sgpt || '-' }}</b-td>
                        <b-td>{{ item.grade_ulkus_diabetikum || '-' }}</b-td>
                        <b-td>{{ item.kreatinin || '-' }}</b-td>
                        <b-td>{{ item.neuropati_dm ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.gangguan_motorik || '-' }}</b-td>
                        <b-td>{{ item.gangguan_sensorik || '-' }}</b-td>
                        <b-td>{{ item.rujuk_rs_co_saraf_otot || '-' }}</b-td>
                        <b-td>{{ item.cholesterol_total || '-' }}</b-td>
                        <b-td>{{ item.ldl || '-' }}</b-td>
                        <b-td>{{ item.hdl || '-' }}</b-td>
                        <b-td>{{ item.trigliserida || '-' }}</b-td>
                        <b-td>{{ item.rujuk_rs_co_profil_lipid ? 'ya' : 'tidak' }}</b-td>

                        <b-td>{{ item.rontgen || '-' }}</b-td>
                        <b-td>{{ item.is_risiko_ginjal_dan_organ ? 'ya' : 'tidak' }}</b-td>
                        <b-td>{{ item.usia || '-' }}</b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td>
                        <b-td>-</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
              <!-- <pre>items {{items}}</pre> -->
            </b-card>
          </b-col>
        </b-row>
        <!-- {{items}} -->
      </b-container>
    </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      total: null,
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        rm_id: null,
        poli: null,
        ms_diagnosa_id: null,
        ms_tenaga_medis_id: null,

        merokok: null,
        kurang_aktifitas_fisik: null,
        gula_belebihan: null,
        garam_berlebihan: null,
        lemak_berlebihan: null,
        kurang_sayur_dan_buah: null,
        konsumsi_alkohon: null,
        rujukan_fktp: null,
        rujuk_rs: null,
        wilayah: null,
        jenis_kunjungan: null,
        jenis_kelamin: null,
        capaian_spm: null,
        kecamatan: null,
        kelurahan: null,
      },
      totalRows: 0,
      items: [],
      listAsuransi: [
        {value: 'bpjs', text: 'BPJS'},
        {value: 'umum', text: 'Umum'},
      ],
      listMerokok: [
        {value: 'ya', text: 'Ya'},
        {value: 'pasif', text: 'Pasif'},
        {value: 'tidak', text: 'Tidak'},
      ],
      listCapaianSPM: [
        {value: 'hipertensi', text: 'Hipertensi'},
        {value: 'diabetes', text: 'Diabetes'},
        {value: 'obesitas', text: 'Obesitas'},
        {value: 'usia produktif', text: 'Usia Produktif'},
        {value: 'umur >= 15 tahun', text: 'Umur >= 15 tahun'},
      ],
      listTenagaMedis: [],
      listPoli: [],
      listDiagnosa: [],
      listPasien: [],
      listKecamatan: [],
      listKelurahan: [],
      filter: null,
      tableBusy: false,
      disabled_field: false,
    };
  },
  computed: {
  },
  activated(){
    this.getData()
    this.reset()
  },
  methods: {
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listDiagnosa = await vm.$axios.post('/ms_diagnosa/list')
        vm.listDiagnosa = listDiagnosa.data.status == 200 ? listDiagnosa.data.data : []
        // let listTenagaMedis = await vm.$axios.post("/tenaga_medis/list", {nama_jenis_tenaga_medis: 'dokter'})
        let listTenagaMedis = await vm.$axios.post("/tenaga_medis/list")
        vm.listTenagaMedis = listTenagaMedis.data.status == 200 ? listTenagaMedis.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getPasien(e){
      const vm = this
      console.log(e)
      vm.tableBusy = false
      vm.listPasien = []
      try {
        const listPasien = await vm.$axios.post('/rm/list', {
          puskesmas_id: null,
          rm_id: e.rm_id,
        })
        vm.listPasien = listPasien.data.status == 200 ? listPasien.data.data : []
        console.log('listPasien', listPasien)
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getKecamatan(e){
      const vm = this
      console.log(e)
      vm.tableBusy = false
      vm.data_search.kelurahan = null
      vm.listKecamatan = []
      try {
        const listKecamatan = await vm.$axios.post('/ms_kecamatan/list', {
          nama_kecamatan: e,
          halaman: '0',
          jumlah: '100',
        })
        vm.listKecamatan = listKecamatan.data.status == 200 ? listKecamatan.data.data : []
        console.log('listKecamatan', listKecamatan)
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getKelurahan(e){
      const vm = this
      console.log(e)
      vm.tableBusy = false
      vm.listKelurahan = []
      try {
        const listKelurahan = await vm.$axios.post('/ms_kelurahan/list', {
          ms_kecamatan_id: vm.data_search.kecamatan ? vm.data_search.kecamatan.ms_kecamatan_id : null,
          nama_kelurahan: e.nama_kelurahan,
          halaman: '0',
          jumlah: '100',
        })
        vm.listKelurahan = listKelurahan.data.status == 200 ? listKelurahan.data.data : []
        console.log('listKelurahan', listKelurahan)
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        const x = vm.data_search
        const res = await vm.$axios.post('/ptm/laporan_kunjungan_ptm', {
          ...this.data_search,
          tanggal_awal: vm.$moment(x.tanggal_awal).format('YYYY-MM-DD'),
          tanggal_akhir: vm.$moment(x.tanggal_akhir).format('YYYY-MM-DD'),
          rm_id: x.rm_id ? x.rm_id.rm_id : null,
          ms_poli_id: x.poli ? x.poli.ms_poli_id : null,
          ms_diagnosa_id: x.ms_diagnosa_id ? x.ms_diagnosa_id.ms_diagnosa_id.ms_diagnosa_id : null,
          ms_tenaga_medis_id: x.ms_tenaga_medis_id ? x.ms_tenaga_medis_id.tenaga_medis_id : null,
          merokok: x.merokok ? x.merokok.value : null,
          kurang_aktifitas_fisik: x.kurang_aktifitas_fisik ? x.kurang_aktifitas_fisik.value : null,
          gula_belebihan: x.gula_belebihan ? x.gula_belebihan.value : null,
          garam_berlebihan: x.garam_berlebihan ? x.garam_berlebihan.value : null,
          lemak_berlebihan: x.lemak_berlebihan ? x.lemak_berlebihan.value : null,
          kurang_sayur_dan_buah: x.kurang_sayur_dan_buah ? x.kurang_sayur_dan_buah.value : null,
          konsumsi_alkohon: x.konsumsi_alkohon ? x.konsumsi_alkohon.value : null,
          rujukan_fktp: x.rujukan_fktp ? x.rujukan_fktp.value : null,
          rujuk_rs: x.rujuk_rs ? x.rujuk_rs.value : null,
          wilayah: x.wilayah ? x.wilayah.value : null,
          jenis_kunjungan: x.jenis_kunjungan ? x.jenis_kunjungan.value : null,
          jenis_kelamin: x.jenis_kelamin ? x.jenis_kelamin.value : null,
          capaian_spm: x.capaian_spm ? x.capaian_spm.value : null,
          ms_kecamatan_id: x.kecamatan ? x.kecamatan.ms_kecamatan_id : null,
          ms_kelurahan_id: x.kelurahan ? x.kelurahan.ms_kelurahan_id : null,
        })
        console.log('ptm/laporan_kunjungan_ptm', res)
        vm.items = []
        const items = []
        if(res.data.status == 200){
          vm.totalRows = res.data.data.length
          for (let i = 0; i < res.data.data.length; i++) {
            const x = res.data.data[i];
            items.push({
              ...x,
              no: i + 1,
            })
          }
          console.log('laporan_kunjungan_ptm', res)
        }
        vm.items = items
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.data_search = {
        tanggal_awal:  new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_akhir: new Date(vm.$store.state.tanggal_sesi),
        rm_id: null,
        poli: null,
        ms_diagnosa_id: null,
        ms_tenaga_medis_id: null,
        merokok: null,
        kurang_aktifitas_fisik: null,
        gula_belebihan: null,
        garam_berlebihan: null,
        lemak_berlebihan: null,
        kurang_sayur_dan_buah: null,
        konsumsi_alkohon: null,
        rujukan_fktp: null,
        rujuk_rs: null,
        wilayah: null,
        jenis_kunjungan: null,
        jenis_kelamin: null,
        capaian_spm: null,
        kecamatan: null,
        kelurahan: null,
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
